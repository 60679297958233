import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import TextContainer from "../../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 6em 0em 0em 0em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  padding: 0em 5em !important;
  text-align: justify !important;
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Our Experience" keywords={[`host family english`, `hfe`]} />
    <Grid
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <GridRow columns="2" centered>
        <GridColumn>
          <Image
            src="https://res.cloudinary.com/mystay-international/image/upload/c_fill,h_175,w_175/v1555552403/Host%20Family%20English/Jo-Humphreys.jpg"
            circular
            size="small"
            verticalAlign="middle"
            fluid
            style={{
              margin: "auto",
              display: "block",
            }}
          />
          <h2 style={{ textAlign: "center" }}>Joanne Humphreys</h2>
          <p>
            Joanne is a senior education-training specialist in the English
            Language with over 20 years’ experience in education, training and
            development.
          </p>
          <p>
            She is a qualified teacher and achieved her Business MSc degree from
            Hertfordshire University. Joanne has worked on curriculum design and
            methodology for Erasmus University in Rotterdam on their
            undergraduate program for the Business Administration course and
            also worked in the Pilgrims’ Teacher Training Unit, famous for their
            innovative and cutting-edge approaches to methodology and creative
            teaching and mentoring in the classroom.
          </p>
          <p>
            Joanne has extensive experience within the education and training
            sectors in the UK, Europe, Australia and the Middle East and holds a
            Project Management qualification (PRINCE2 Practitioner) as well as
            NVQ’s at Level 5 and 7. Joanne speaks Spanish fluently with a good
            command of Dutch, Italian and French. Joanne has been a Principal
            for the Colleges of Excellence in Saudi Arabia, one of the flagship
            schools of the Gins Alliance group (Harven School of English) and
            also managed the Learn Direct program for Pilgrims in Canterbury.
          </p>
        </GridColumn>
        <GridColumn>
          <Image
            src="https://res.cloudinary.com/mystay-international/image/upload/c_fill,h_175,w_175/v1555552549/Host%20Family%20English/Rory-Photo.jpg"
            circular
            size="small"
            verticalAlign="middle"
            fluid
            style={{
              margin: "auto",
              display: "block",
            }}
          />
          <h2 style={{ textAlign: "center" }}>Rory Mooney</h2>
          <p>
            Rory has held a number of senior positions in the field of teaching
            and learning. His expertise revolves around the design of Mentoring,
            Leadership, Management programmes, systems and support.
          </p>
          <p>
            He runs his own consultancy business that focuses on developing
            people: great leaders, managers, mentors, and teachers, so that they
            can drive school improvement programmes, heighten learner engagement
            and increase leaner outcomes.
          </p>
          <p>
            Rory has been both a teacher and a Principal at primary and
            secondary levels. He has worked as a Community College instructor in
            the United States, and as an adjunct professor at the University of
            California, he has also undertaken roles from Curriculum and
            Teaching and Learning advisor, to Education Director, Middle East &
            South East Asia for one of the largest private schools groups.
            Furthermore, he has been Head of Services, for the Royal Borough of
            Kensington and Chelsea, and Global Head of Product Development at
            the International Baccalaureate Organization (IB). Rory is a serious
            education reformer through the development of leaders, managers,
            mentors and teachers.
          </p>
          <p>
            Rory holds a Bachelor’s degree from the University of London, and a
            Master’s degree in Educational Leadership from the University of
            California.
          </p>
        </GridColumn>
      </GridRow>
      <GridRow columns="1" centered>
        <GridColumn>
          <Image
            src="https://res.cloudinary.com/mystay-international/image/upload/c_fill,h_175,w_175/v1555552936/Host%20Family%20English/IMG_98321.jpg"
            circular
            size="small"
            verticalAlign="middle"
            fluid
            style={{
              margin: "auto",
              display: "block",
            }}
          />
          <h2 style={{ textAlign: "center" }}>David Bycroft</h2>
          <p>
            David is one of Australia’s most successful business developers and
            a recognized quality advocate for International Student services.
          </p>
          <p>
            David commenced his work in the International Education Industry in
            1999 and has been acknowledged as the leader in the development of
            compliance and new service standards for Overseas Student Health
            Cover (OSHC) (1999 to 2007). Between 2002 and 2007, David was the
            lead Marketing Strategist for Allianz Global Assistance to become
            the number one OSHC provider in Australia.
          </p>
          <p>
            The Australian Homestay Network (AHN) was founded by David in 2007,
            and created acceptable guidelines and standards for Homestay in
            Australia – AHN has now placed almost 40,000 international students
            into successful homestay. David created Australia’s first national
            online orientation program for international students with the new
            Allianz Student Welcome Services project and in 2011 David was
            appointed by the NSW Government to be a member of the New South
            Wales Government Taskforce on International Education and Research.
          </p>
          <p>
            David founded the American Homestay Network (AHN USA) in 2012 –
            helping to create global standards for homestay – AHN USA is growing
            rapidly in 2018 and is on track to place over 2,000 international
            students into successful homestay across the USA.
          </p>
          <p>
            International Students presented David with a ‘Remarkable
            Achievement Award’ from the Council of International Students of
            Australia (CISA) in 2013 for his ‘outstanding contribution to
            international students in Australia’.
          </p>
        </GridColumn>
      </GridRow>
    </Grid>
  </Layout>
)

export default IndexPage
